<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex pt-5 xs11 sm11 md11 lg11 xl11>
        <!-- Header Section -->
        <v-layout wrap justify-start class="my-3 ml-2">
          <v-flex
            xs12
            sm3
            md3
            lg2
            text-start
            align-center
            pt-2
            class="headingChurch"
            >Dashboard</v-flex
          >
        </v-layout>

        <v-layout wrap justify-start  v-if="userRole == 'admin'">
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 sm4 pa-2>
                <v-card class="pa-6 elevation-1" color="#D0E7FF" rounded="xl">
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="70" class="white">
                        <v-icon large color="blue">mdi-church</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 lg9 pl-5 class="dashbhead">
                      <div>Total Churches</div>
                      <div
                        class="title font-weight-bold"
                        style="color: #57a8fd"
                      >
                        {{ list.parishes }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <!-- Second Card -->
              <v-flex xs12 sm4 pa-2>
                <v-card class="pa-6 elevation-1" color="#EAD0FF" rounded="xl">
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="70" class="white">
                        <v-icon large color="purple">mdi-cross</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 lg9 pl-5 class="dashbhead">
                      <div>Active Masses</div>
                      <div class="title font-weight-bold" style="color: purple">
                        {{ list.activeMass }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12 sm4 pa-2>
                <v-card class="pa-6 elevation-1" color="#FFE9D0" rounded="xl">
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="70" class="white">
                        <v-icon large color="orange"
                          >mdi-account-multiple</v-icon
                        >
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 lg9 pl-5 class="dashbhead">
                      <div>Total Users</div>
                      <div class="title font-weight-bold" style="color: orange">
                        {{ list.users }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12 sm4 pa-2>
                <router-link :to="'/pendingChurch'" style="text-decoration: none">
                <v-card class="pa-6 elevation-1" color="#FFF7D0" rounded="xl">
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="70" class="white">
                        <v-icon large color="#FFCF00">mdi-account-cog</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 lg9 pl-lg-5 pl-sm-2 class="dashbhead">
                      <div>Pending Churches</div>
                      <div
                        class="title font-weight-bold"
                        style="color: #ffcf00"
                      >
                        {{ list.pendingParishes }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
                </router-link>
              </v-flex>

              <v-flex xs12 sm4 pa-2>
                <v-card class="pa-6 elevation-1" color="#FFD0D0" rounded="xl">
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="70" class="white">
                        <v-icon large color="#DD3B3B">mdi-youtube</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 lg9 pl-5 class="dashbhead">
                      <div>Live Telecasts</div>
                      <div
                        class="title font-weight-bold"
                        style="color: #dd3b3b"
                      >
                        {{ list.liveTelecastsNow }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12 sm4 pa-2>
                <v-card class="pa-6 elevation-1" color="#D9FFD0" rounded="xl">
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="70" class="white">
                        <v-icon large color="#54E78F">
                          mdi-shape-plus-outline</v-icon
                        >
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 lg9 pl-5 class="dashbhead">
                      <div>Total Rites</div>
                      <div
                        class="title font-weight-bold"
                        style="color: #54e78f"
                      >
                        {{ list.rites }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <!-- <v-flex xs12 sm6 pa-2>
                <v-card rounded="xl">
                  <v-img :src="require('@/assets/images/b1.png')">
                    <v-layout
                      column
                      align-left
                      pa-4
                      pl-6
                      pt-6
                      style="color: white; font-family: 'interregular'"
                    >
                      <v-flex
                        xs12
                        class="font-weight-medium"
                        style="font-size: 20px"
                      >
                        Live Masses around the world
                      </v-flex>

                      <v-flex xs12 class="mt-2" style="font-size: 28px">
                        14.5 K
                      </v-flex>

                      <v-flex xs12 class="mt-2" style="font-size: 12px">
                        “I can do all this through him who gives me strength.”
                        <v-flex xs11 sm11 md10 lg8 pt-2 text-end>
                          <div>Philippians 4:13</div>
                        </v-flex>
                      </v-flex>
                    </v-layout>
                  </v-img>
                </v-card>
              </v-flex> -->

              <v-layout wrap>
                <v-flex xs12 class="graph" pl-2>
                  Total Monthly Users
                  <v-flex xs12 sm4 md3 lg3  mt-3>
                 
             
                 
                    <v-select small 
                    v-model="selectedYear"
                    :items="years"
                    label="Select Year"
                    @change="getUsage" :hide-details="true"
                    
                   dense
                  outlined
                  class="rounded-xl"
                 
                  ></v-select>
                  </v-flex>
                 
                  <div id="chart" v-if="flag">
                    <apexchart
                      type="bar"
                      height="400"
                      :options="chartOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </v-flex>
              </v-layout>
              
              <!-- <v-layout wrap>
                <v-flex xs12>
                  <V-flex pl-2 class="graph">

                    Total Monthly Users
                  </V-flex>
                  <div id="chart" v-if="flag">
                    <apexchart
                      type="bar"
                      height="400"
                      :options="chartOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </v-flex>
              </v-layout> -->
            </v-layout>
          </v-flex>
        </v-layout>



        <v-layout wrap justify-start v-if="userRole == 'subadmin'">
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 sm4 pa-2>
                <v-card class="pa-6 elevation-1" color="#D0E7FF" rounded="xl">
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="70" class="white">
                        <v-icon large color="blue">mdi-church</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 lg9 pl-5 class="dashbhead">
                      <div>Total Churches</div>
                      <div
                        class="title font-weight-bold"
                        style="color: #57a8fd"
                      >
                        {{ list.parishes }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <!-- Second Card -->
              <v-flex xs12 sm4 pa-2>
                <v-card class="pa-6 elevation-1" color="#EAD0FF" rounded="xl">
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="70" class="white">
                        <v-icon large color="purple">mdi-cross</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 lg9 pl-5 class="dashbhead">
                      <div>Active Masses</div>
                      <div class="title font-weight-bold" style="color: purple">
                        {{ list.activeMass }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <!-- <v-flex xs12 sm4 pa-2>
                <v-card class="pa-6 elevation-1" color="#FFE9D0" rounded="xl">
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="70" class="white">
                        <v-icon large color="orange"
                          >mdi-account-multiple</v-icon
                        >
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 lg9 pl-5 class="dashbhead">
                      <div>Total Users</div>
                      <div class="title font-weight-bold" style="color: orange">
                        {{ list.users }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex> -->
<!-- 
              <v-flex xs12 sm4 pa-2>
                <v-card class="pa-6 elevation-1" color="#FFF7D0" rounded="xl">
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="70" class="white">
                        <v-icon large color="#FFCF00">mdi-account-cog</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 lg9 pl-lg-5 pl-sm-2 class="dashbhead">
                      <div>Parish Managers</div>
                      <div
                        class="title font-weight-bold"
                        style="color: #ffcf00"
                      >
                        {{ list.parishManagers }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex> -->

              <v-flex xs12 sm4 pa-2>
                <v-card class="pa-6 elevation-1" color="#FFD0D0" rounded="xl">
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="70" class="white">
                        <v-icon large color="#DD3B3B">mdi-youtube</v-icon>
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 lg9 pl-5 class="dashbhead">
                      <div>Live Telecasts</div>
                      <div
                        class="title font-weight-bold"
                        style="color: #dd3b3b"
                      >
                        {{ list.liveTelecastsNow }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <!-- <v-flex xs12 sm4 pa-2>
                <v-card class="pa-6 elevation-1" color="#D9FFD0" rounded="xl">
                  <v-layout wrap justify-center align-center>
                    <v-flex xs12 lg3 text-center>
                      <v-avatar size="70" class="white">
                        <v-icon large color="#54E78F">
                          mdi-shape-plus-outline</v-icon
                        >
                      </v-avatar>
                    </v-flex>
                    <v-flex xs12 lg9 pl-5 class="dashbhead">
                      <div>Total Rites</div>
                      <div
                        class="title font-weight-bold"
                        style="color: #54e78f"
                      >
                        {{ list.rites }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex> -->

              <!-- <v-flex xs12 sm6 pa-2>
                <v-card rounded="xl">
                  <v-img :src="require('@/assets/images/b1.png')">
                    <v-layout
                      column
                      align-left
                      pa-4
                      pl-6
                      pt-6
                      style="color: white; font-family: 'interregular'"
                    >
                      <v-flex
                        xs12
                        class="font-weight-medium"
                        style="font-size: 20px"
                      >
                        Live Masses around the world
                      </v-flex>

                      <v-flex xs12 class="mt-2" style="font-size: 28px">
                        14.5 K
                      </v-flex>

                      <v-flex xs12 class="mt-2" style="font-size: 12px">
                        “I can do all this through him who gives me strength.”
                        <v-flex xs11 sm11 md10 lg8 pt-2 text-end>
                          <div>Philippians 4:13</div>
                        </v-flex>
                      </v-flex>
                    </v-layout>
                  </v-img>
                </v-card>
              </v-flex> -->
<!-- 
              <v-layout wrap>
                <v-flex xs12 class="graph" pl-2>
                  Total Monthly Users
                  <v-flex xs12 sm4 md3 lg3   mt-3>
                 
             
                 
                    <v-select small 
                    v-model="selectedYear"
                    :items="years"
                    label="Select Year"
                    @change="getUsage" :hide-details="true"
                    
                   dense
                  outlined
                  class="rounded-xl"
                 
                  ></v-select>
                  </v-flex>
                 
                  <div id="chart" v-if="flag">
                    <apexchart
                      type="bar"
                      height="400"
                      :options="chartOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </v-flex>
              </v-layout> -->
              
              <!-- <v-layout wrap>
                <v-flex xs12>
                  <V-flex pl-2 class="graph">

                    Total Monthly Users
                  </V-flex>
                  <div id="chart" v-if="flag">
                    <apexchart
                      type="bar"
                      height="400"
                      :options="chartOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </v-flex>
              </v-layout> -->
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
//import VueApexCharts from 'vue-apexcharts';
export default {
  // components: {
  //   apexchart: VueApexCharts,
  // },
  data() {
    return {
      userRole: localStorage.getItem("role"),
      list: {},

      graph: [],
    week: null,
    flg: true,
    flag: false,
    series: [
      {
        name: "Users",
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return +val;
          },
        },
      },
    },
    selectedYear: new Date().getFullYear(), // Initialize with the current year
    years: this.getYears(),
  };
  },

  mounted() {
    const userRole = localStorage.getItem("role");
      if (userRole === 'admin') {
      this.getList();
    } else if (userRole === 'subadmin') {
      this.getDataForSubAdmin();
    }
    this.getUsage();
    // this.setDefaultDay();
  },
  methods: {
    getYears() {
    const startYear = 2020;
    const currentYear = new Date().getFullYear();
    const futureYear = currentYear + 50; // Adjust this to control how far into the future the list should go
    const years = [];
    for (let year = startYear; year <= futureYear; year++) {
      years.push(year);
    }
    return years;
  },
    getUsage() {
    this.appLoading = true;
    this.flag = false;
    this.usage = [];
    this.series[0].data = [];
    this.chartOptions.xaxis.categories = [];

    axios({
      method: "GET",
      url: "/get/graph/users/monthly",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params: {
        year: this.selectedYear, // Use the selected year
      },
    })
      .then((response) => {
        this.appLoading = false;
        this.usage = response.data.data || {};
        this.graph = response.data.data;

        for (var i = 0; i < this.graph.length; i++) {
          this.chartOptions.xaxis.categories.push(this.graph[i].month);
          this.series[0].data.push(this.graph[i].count);
        }
        this.flag = true;
      })
      .catch((err) => {
        this.appLoading = false;
        this.ServerError = true;
        console.log(err);
      });
  },
    // getUsage() {
    //   this.appLoading = true;
    //   this.flag = false;
    //   this.usage = [];
    //   this.series[0].data = [];
    //   this.chartOptions.xaxis.categories = [];

    //   if (!this.year) {
    //     this.year = new Date().getFullYear();
    //   }

    //   axios({
    //     method: "GET",
    //     url: "/get/graph/users/monthly",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     params: {
    //       year: this.year,
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       this.usage = response.data.data || {};
    //       // Handle 'graph' data safely
    //       this.graph = response.data.data;

    //       // Prepare the data for the chart
    //       // const months = this.graph.map((item) => `Month ${item.month}`);
    //       // const counts = this.graph.map((item) => item.count);
    //       // console.log("months",months)
    //       for (var i = 0; i < this.graph.length; i++) {
    //         this.chartOptions.xaxis.categories.push(this.graph[i].month);
    //         this.series[0].data.push(this.graph[i].count);
    //       }
    //       this.flag = true;
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },

   getList() {
    this.appLoading = true;

    // Get the current time
    const now = new Date();

    // Apply timezone offset to get local time in milliseconds
    const localTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);

    // Convert to ISO string
    const currentTime = localTime.toISOString();

    axios({
        method: "GET",
        url: "/get/dashboard/datas",
        headers: {
            token: localStorage.getItem("token"),
        },
        params: {
            currentTime: currentTime,
        },
      })
      .then((response) => {
          this.list = response.data;
          this.appLoading = false;
      })
      .catch((err) => {
          this.ServerError = true;
          console.log(err);
      });
},

getDataForSubAdmin() {
    this.appLoading = true;

    // Get the current time
    const now = new Date();

    // Apply timezone offset to get local time in milliseconds
    const localTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);

    // Convert to ISO string
    const currentTime = localTime.toISOString();

    axios({
        method: "GET",
        url: "/get/subadmin/dashboard/datas",
        headers: {
            token: localStorage.getItem("token"),
        },
        params: {
            currentTime: currentTime,
        },
      })
      .then((response) => {
          this.list = response.data;
          this.appLoading = false;
      })
      .catch((err) => {
          this.ServerError = true;
          console.log(err);
      });
}

  },
};
</script>
